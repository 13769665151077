import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import Vue3VideoPlayer from '@cloudgeek/vue3-video-player';
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css';

import "vue3-paginate-z/dist/styles.css";
import Paginate from "vue3-paginate-z";

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import CKEditor from '@ckeditor/ckeditor5-vue'

import Vue3DraggableResizable from 'vue3-draggable-resizable'
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'

import bottomNavigationVue from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";

import SocketIO from 'socket.io-client'

// import VueSocketIO from 'vue-3-socket.io'

const app = createApp(App)

var domain_web = 'https://vaohang.tech/api/'
var domain_webf8 = 'https://f8game.vn/'

app.config.globalProperties.url_param = 'vaohang10.tv'

app.config.globalProperties.link_web = 'https://f8game.click/'
app.config.globalProperties.link_webs = 'https://f8game.live/'
app.config.globalProperties.link_video = 'https://videof8bet.vip/video/'
app.config.globalProperties.link_imgvideo = 'https://api.videof8bet.vip/'

app.config.globalProperties.api_country = 'https://vaohang.dev/result_country.php'
app.config.globalProperties.api_listmatchdate = domain_web + 'danh-sach/tat_ca?sort=day'
app.config.globalProperties.api_listmatchlive = domain_web + 'danh-sach/tat_ca'
app.config.globalProperties.api_blv = domain_web + 'blv'
app.config.globalProperties.api_ltd = 'https://vaohang.dev/result_fixtures.php'
app.config.globalProperties.api_listicon = 'https://f8betchat.com/api/list-sticker'
app.config.globalProperties.api_listidol = 'https://f8game.click/api/list-idol-f8'
app.config.globalProperties.api_listResult = 'https://keonhacai.1nguon.vip/result.json'

app.config.globalProperties.api_countrybxh = domain_webf8 + 'apilist.php'
app.config.globalProperties.api_listbxh = domain_webf8 + 'apibxh.php'

app.config.globalProperties.api_ratio = domain_webf8 + 'apitilekeo.php'
app.config.globalProperties.api_kqbd = domain_webf8 + 'apiketqua.php'
app.config.globalProperties.api_ltd = domain_webf8 + 'apilichthidau.php'
app.config.globalProperties.api_previewLink = domain_web + 'link'
app.config.globalProperties.api_listMatchApi = domain_web + 'match'

app.config.globalProperties.api_listvideo = 'https://api.videof8bet.vip/api/video/getPagingV2?pageSize=12'
// app.config.globalProperties.api_listvideo = 'https://f8game.info/items/read.php'

app.config.globalProperties.api_bxh = 'https://vaohang.tech/api/league'


app.config.globalProperties.api_sport1 = 'https://ovo.eelov.com/live/n-1/chunks.m3u8'
app.config.globalProperties.api_sport2 = 'http://202.21.104.6:88/sps-prime/index.m3u8'


var domain_webs = 'https://f8game.click/api/'
app.config.globalProperties.api_register = domain_webs + 'register'
app.config.globalProperties.api_login = domain_webs + 'login'
app.config.globalProperties.api_logout = domain_webs + 'logout'

app.config.globalProperties.link_web = 'https://f8game.click/'
var domain_chat = 'https://chat.f8live.click/api/'
app.config.globalProperties.link_webchat = 'https://chat.f8live.click/'
app.config.globalProperties.api_loginChat = domain_chat + 'login'
app.config.globalProperties.api_listidolmes = domain_chat + 'list-user'
app.config.globalProperties.api_listType = domain_chat + 'list-group-user'
app.config.globalProperties.api_addType = domain_chat + 'list-group-user-detail-add'
app.config.globalProperties.api_deleteType = domain_chat + 'list-group-user-detail-delete'
app.config.globalProperties.api_detailMes = domain_chat + 'group-detail-message'
app.config.globalProperties.api_sendMes = domain_chat + 'add-message'
app.config.globalProperties.api_deleteMes = domain_chat + 'delete-message'
app.config.globalProperties.api_pinMes = domain_chat + 'pin-message'
app.config.globalProperties.api_delepinMes = domain_chat + 'delete-pin-message'

var domain_icon = 'https://f8betchat.com/api/'
app.config.globalProperties.domainIcon = 'https://f8betchat.com/'
app.config.globalProperties.api_listicon = domain_icon + 'list-sticker'

app.config.globalProperties.api_matchCola = domain_web + 'match-live'
// app.config.globalProperties.api_matchCola = 'https://colatv.cfd/business/index/matchLive'


app.config.globalProperties.socket = SocketIO('https://f8betchat.com', { transports: ['websocket', 'polling', 'flashsocket'], });
app.use(router)
app.use(VueAxios, axios)
app.use(Vue3VideoPlayer)
app.use(Paginate)
app.use(bottomNavigationVue)
app.use(VueSweetalert2)

// app.use(new VueSocketIO({
//     debug: false,
//     connection: 'https://f8betchat.com',
//     transports: ['websocket', 'polling', 'flashsocket'],
//     vuex: {
//         actionPrefix: 'SOCKET_',
//         mutationPrefix: 'SOCKET_'
//     },
//     options: { path: "" }
// }))
app.use(Vue3DraggableResizable)
app.use(CKEditor)
app.mount('#app')
