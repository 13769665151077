import { createRouter, createWebHistory } from 'vue-router'

import Home_vaohang from '@/components/Home.vue'
// import Phatsongtructuyen_vh from '@/components/Phatsongtructuyen.vue'
import Lichthidau_vh from '@/components/Lichthidau.vue'
import Highlight_vh from '@/components/Highlight.vue'
import Highlightvideo_vh from '@/components/Highlightvideo'
import Kqbd_vh from '@/components/Kqbd'
import Bxh_vh from '@/components/Bxh.vue'
import News_vh from '@/components/New.vue'
import Live_vh from '@/components/Live.vue'
import Livevh_vh from '@/components/Livevh.vue'
import Ratio_vh from '@/components/Ratio.vue'
import Kcong_vh from '@/components/Kcong.vue'

const routes = [
    {
        path: '/',
        name: 'Home_vaohang',
        component: Home_vaohang
    },
    // {
    //     path: '/phat-song-truc-tuyen/:pl/:id/:url',
    //     name: 'Phatsongtructuyen_vh',
    //     component: Phatsongtructuyen_vh
    // },
    {
        path: '/lich-thi-dau',
        name: 'Lichthidau_vh',
        component: Lichthidau_vh
    },
    {
        path: '/highlight',
        name: 'Highlight_vh',
        component: Highlight_vh
    },
    {
        path: '/highlight-video/:id',
        name: 'Highlightvideo_vh',
        component: Highlightvideo_vh
    },
    {
        path: '/kqbd',
        name: 'Kqbd_vh',
        component: Kqbd_vh
    },
    {
        path: '/bxh',
        name: 'Bxh_vh',
        component: Bxh_vh
    },
    {
        path: '/tin-tuc',
        name: 'News_vh',
        component: News_vh
    },
    {
        path: '/live/:id/:check/:url',
        name: 'Live_vh',
        component: Live_vh
    },
    {
        path: '/ratio',
        name: 'Ratio_vh',
        component: Ratio_vh
    },
    {
        path: '/livevh/:id/:url',
        name: 'Livevh_vh',
        component: Livevh_vh
    },
    {
        path: '/k+/:id',
        name: 'Kcong_vh',
        component: Kcong_vh
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router