<template>
    <div class="container content-margin maxwidth_container">
        <div class="wrapper_live_detail" :class="{ active: checkPlay == 2 }">
            <div class="live_room" v-for="(lives, indexs) in detailPlay" v-bind:key="indexs">
                <div class="root_liveRoom">
                    <div class="video_live_room video_live_room_live_mb">
                        <div class="row" v-if="checkPlay == 2">
                            <div class="col-md-9 col-sm-9 col-xs-12 left_videohome">
                                <div class="videohome_lf bgr_video">
                                    <div class="video_vietnam">
                                        <div class="video_edit" v-if="retrieveAllCustomer(lives.result.match.videoUrl) == 1">
                                            <div class="videotvc_adv video_detail_tvc" v-if="checkClickAdv == 0">
                                                <video controls autoplay loop webkit-playsinline playsinline>
                                                    <source src="https://f8game.click/tvc/tvc_adv.mp4" type="video/mp4">
                                                </video>
                                                <span class="nextvideo" @click="nextVideo">Bỏ qua</span>
                                            </div>
                                            <vue3-video-player
                                                autoplay
                                                :core="HLSCore"
                                                :src="lives.result.match.videoUrl"
                                                title=""
                                                resolution="408p"
                                                :view-core="viewCore.bind(null, 'videolivecam')"
                                                id="videolivecam"
                                                v-else
                                            />
                                        </div>
                                        <div class="video_edit" v-else>
                                            <vue3-video-player
                                                autoplay
                                                :core="HLSCore"
                                                :src="'https://cdn-2.nxplay.com.br/ESPN/tracks-v1a1/mono.m3u8'"
                                                title=""
                                                resolution="408p"
                                                :view-core="viewCore.bind(null, 'videolivecamtvc')"
                                                id="videolivecamtvc"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ghim_link pin_mobile">
                                <p><a target="_blank" :href="linkTele">👉Vào nhóm nhận kèo từ BLV với hơn 3000 bet thủ: <span>{{ linkTele }}</span></a></p>
                                <p><a target="_blank" :href="linkCuoc">👉CƯỢC NGAY: <span>{{ linkCuoc }}</span></a></p>
                                <p><a target="_blank" :href="'https://zalo.me/'+ linkZalo">☎️ZALO : {{ linkZalo }}</a></p>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12 right_chathome">
                                <div class="chathome">
                                    <div class="wraper_chat">
                                        <div class="ghim_link pin_desktop">
                                            <p><a target="_blank" :href="linkTele">👉Vào nhóm nhận kèo từ BLV với hơn 3000 bet thủ: <span>{{ linkTele }}</span></a></p>
                                            <p><a target="_blank" :href="linkCuoc">👉CƯỢC NGAY: <span>{{ linkCuoc }}</span></a></p>
                                            <p><a target="_blank" :href="'https://zalo.me/'+ linkZalo">☎️ZALO : {{ linkZalo }}</a></p>
                                        </div>
                                        <form method="post" action="" name="frm_chat" id="_frm_chat" @submit="sendChat">
                                            <div class="chat-text" v-bind:id="idLv">
                                            </div>
                                            <div class="btn-chatbd">
                                                <div class="name_chat">
                                                    <input type="text" v-model="name" placeholder="Tên hiển thị">
                                                    <img src="../assets/images/icon.png" alt="" class="show_list_icon" @click="showIconChatLive">
                                                    <div class="list_icon_chat">
                                                        <div class="item_icon_chat">
                                                            <p v-for="(item, index) in listIcon" v-bind:key="index">
                                                                <img :data_img="items.resource" :src="'https://f8betchat.com/' + items.resource" alt="" v-for="(items, indexs) in item" v-bind:key="indexs" class="icon_chat_send" @click="sendIconChat">
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="hidden" name="id-chat" class="id-chat" v-bind:value="idLv">
                                                <input type="hidden" name="url-chat" class="url-chat" v-bind:value="urlLv">
                                                <input type="hidden" name="data-img" class="dataImg_chat">
                                                <div class="wp-content">
                                                    <ckeditor :editor="editor" :config="editorConfig" v-on:ready="onEditorReady" id="content_chat" v-model="content_chat" class="content_desktop"></ckeditor>
                                                    <!-- <input type="text" class="content_mobile" id="content_chats" v-model="content_chat" placeholder="Nội dung"> -->
                                                </div>
                                                <button type="submit" class="btn-submit" id="chat-submit"></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="img_show_hide_chat">
                                    <p class="show_chatss"><img src="../assets/images/show_chat.png" alt=""></p>
                                    <p class="hide_chat"><img src="../assets/images/closec.png" alt=""></p>
                                </div>
                            </div>
                        </div>
                        <div class="bgr_videos" v-else>
                            <vue3-video-player
                                autoplay
                                :core="HLSCore"
                                :src="'https://cdn-2.nxplay.com.br/ESPN/tracks-v1a1/mono.m3u8'"
                                title=""
                                resolution="408p"
                                :view-core="viewCore.bind(null, 'videolivecamtvc')"
                                id="videolivecamtvc"
                            />
                            <!-- <video width="100%" height="640" autoplay loop controls class="video-highlight-home" style="width: 100%;" webkit-playsinline playsinline>
                                <source src="https://f8game.click/tvc/tvc_vaohang.mp4" type="video/mp4">
                            </video> -->
                        </div>
                        <div class="ratio_match_live" v-if="checkPlay == 2">
                            <div class="item_matchlive">
                                <ul>
                                    <li>
                                        <p class="title_rt">Cược chấp</p>
                                        <p class="p_rt" v-for="(item1, index1) in rati1" :key="index1">
                                            <span class="span_fc">{{item1.fractionPoint}}</span><br />
                                            <span class="span_lc">{{ item1.odds1a}}</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p class="title_rt">1x2</p>
                                        <div v-for="(item5, index5) in rati5" :key="index5">
                                            <p class="p_rt p_rt1">
                                                <span class="span_lc">{{item5.com1}}</span>
                                            </p>
                                            <p class="p_rt p_rt1">
                                                <span class="span_lc">{{ item5.com2}}</span>
                                            </p>
                                            <p class="p_rt p_rt1">
                                                <span class="span_lc">{{ item5.comx}}</span>
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <p class="title_rt">tài xỉu</p>
                                        <p class="p_rt" v-for="(item3, index3) in rati3" :key="index3">
                                            <span class="span_fc">{{item3.fractionPoint}}</span><br />
                                            <span class="span_lc"> {{ item3.odds1a}}</span>
                                        </p>
                                    </li>
                                    <li class="cuocf8_btn"><a :href="linkCuoc" target="_blank"><img src="../assets/images/cn.gif" alt=""></a></li>
                                    <li class="keovip_btn"><a :href="linkKeo" target="_blank"><img src="../assets/images/kv.gif" alt=""></a></li>
                                </ul>
                            </div>
                            <div class="btn_cuoc_mb" style="display:none;">
                                <ul>
                                    <li><a :href="linkKeo" target="_blank"><img src="../assets/images/kv.gif" alt=""></a></li>
                                    <li><a :href="linkCuoc" target="_blank"><img src="../assets/images/cn.gif" alt=""></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="root_kcong_bner" style="display:none !important;">
                <div class="wp_kcong">
                    <div class="kcong kcong1">
                        <router-link v-bind:to="{ name: 'Kcong_vh', params: { id: 1 } }">
                            <img src="../assets/images/kc1.png" alt="">
                        </router-link>
                    </div>
                    <div class="kcong kcong2">
                        <router-link v-bind:to="{ name: 'Kcong_vh', params: { id: 2 } }">
                            <img src="../assets/images/kc2.png" alt="">
                        </router-link>
                    </div>
                    <!-- <div class="kcong kcong3">
                        <router-link v-bind:to="{ name: 'Kcong_vh', params: { id: 3 } }">
                            <img src="../assets/images/htv.png" alt="">
                        </router-link>
                    </div> -->
                </div>
            </div>
            <div class="wrapper_match_home" v-if="listMatchApiAll.length > 0">
                <div class="title_match_home">
                    <h4><img src="../assets/images/sdr.png" alt=""> Sắp diễn ra</h4>
                </div>
                <div class="row row_match_home">
                    <div class="col-md-12 col-sm-12 col-xs-12 right_table_match">
                        <div class="tab-content">
                            <div class="allmatch">
                                <div>
                                    <div class="item-list" v-for="(item, index) in listMatchApiAll" v-bind:key="index">
                                        <router-link class="nav-item" v-bind:to="{ name: 'Live_vh', params: { id: item.result.match.id, check: item.result.match.matchStatus, url: item.result.match.homeTeam.slug + '-vs-' + item.result.match.awayTeam.slug + '-' +  item.result.match.id } }">
                                            <div class="row">
                                                <div class="col-md-5 col-sm-5 col-xs-12 left-list">
                                                    <div class="date-match">
                                                        <p class="competition_name nonedk" style="display:none;">{{ replaceName(item.result.match.competition.slug) }}</p>
                                                        <p>
                                                            <span class="date-lt">{{ convertDateStame(convertDatetime(item.result.match.matchTime)) }}</span>
                                                            <span class="nonemb">{{ replaceName(item.result.match.competition.slug) }}</span>
                                                            <span class="blv-class"><img src="../assets/images/live_1.gif" alt="" v-if="item.result.match.matchStatus == 2"></span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-7 col-sm-7 col-xs-12 center-list">
                                                    <div class="name-match">
                                                        <p class="">
                                                            <span class="name-lt">{{ replaceName(item.result.match.homeTeam.slug) }}</span>
                                                            <span class="logo-vs">
                                                                <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.homeTeam.logo" class="img-logolt" alt="">
                                                                <span v-if="item.result.match.matchStatus == 2 && typeof item?.result?.match?.homeScores !== 'undefined'">{{ item.result.match.homeScores[0] }} - {{ item.result.match.awayScores[0] }}</span>
                                                                <span v-else>-</span>
                                                                <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.awayTeam.logo" class="img-logolt" alt="">
                                                            </span>
                                                            <span class="name-lt">{{ replaceName(item.result.match.awayTeam.slug) }}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper_bxh_blv" style="display:none !important;">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12 left_bxh_home">
                        <DetailBxh_vh />
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-4 right_bxh_home" style="display: none !important;">
                        <div class="blv_home">
                            <div class="title_match_home">
                                <h4><img src="../assets/images/blv.png" alt=""> Top bình luận viên</h4>
                            </div>
                            <div class="root_blv_sc">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Tên bình luận viên</th>
                                            <th>Điểm</th>
                                            <th>Trạng thái</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in listblv" :key="index">
                                            <td>
                                                <span class="stt_blv">{{ index + 1 }}</span>
                                                <img :src="'https://vaohang.tech' + item.src_image" alt="">
                                                <span class="name_blv">{{ item.name }}</span>
                                            </td>
                                            <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.point) }}</td>
                                            <td><img src="../assets/images/live.png" alt=""></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="root_ratio_home ratio_tyle" style="display:none !important;">
                <div class="wrapper_ratio_home">
                    <div class="title_ratio">
                        <h3>
                            <img src="../assets/images/tl.png" alt="">
                            <span>Tỷ lệ kèo</span>
                        </h3>
                        <ul>
                            <li>Tỷ lệ</li>
                            <li>Tài xỉu</li>
                            <li>1x2</li>
                        </ul>
                    </div>
                    <form action="" method="post" @change="GetDateRatio($event)">
                        <div class="wp_date_ratio_home">
                            <div class="date_ratio_home active">
                                <input type="radio" checked id="dateratio" class="dateratio" name="dateratio" :value="date1 + '_' + month1">
                                <label for="dateratio">Ngày {{ date1 + '/' + month1 }}</label>
                            </div>
                            <div class="date_ratio_home">
                                <input type="radio" id="dateratio1" class="dateratio" name="dateratio" :value="date2 + '_' + month2">
                                <label for="dateratio1">Ngày {{ date2 + '/' + month2 }}</label>
                            </div>
                            <div class="date_ratio_home">
                                <input type="radio" id="dateratio2" class="dateratio" name="dateratio" :value="date3 + '_' + month3">
                                <label for="dateratio2">Ngày {{ date3 + '/' + month3 }}</label>
                            </div>
                        </div>
                    </form>
                    <div class="ratio_home_wrapper">
                        <div class="wrapper_title_ratio_home">
                            <div class="title_ratio_home">
                                <div class="title_1">trận đấu</div>
                            </div>
                            <div class="title_ratio_home1 border_edittable">
                                <div class="title_2">
                                    <div class="div_1">Cả trận</div>
                                </div>
                                <div class="wp_three_table">
                                    <div class="one_divRatio">Tỷ lệ</div>
                                    <div class="two_divRatio">Tài xỉu</div>
                                    <div class="three_divRatio">1x2</div>
                                </div>
                            </div>
                            <div class="title_ratio_home1 title_ratio_home2">
                                <div class="title_2">
                                    <div class="div_1">
                                        Hiệp 1
                                    </div>
                                </div>
                                <div class="wp_three_table">
                                    <div class="one_divRatio">Tỷ lệ</div>
                                    <div class="two_divRatio">Tài xỉu</div>
                                    <div class="three_divRatio">1x2</div>
                                </div>
                            </div>
                        </div>
                        <div class="wrapper_item_ratio_home">
                            <div class="item_ratio_home item_ratio_home_live" v-for="(item, index) in data_name_ratio2" :key="index">
                                <div class="wrapper_match_ratio">
                                    <div class="name_match_ratio">
                                        <div class="tournament_home">{{ item.leagueName }}</div>
                                        <p class="textred">{{ item.homeName }}</p>
                                        <p class="vsratio"><img src="../assets/images/vs.png" alt=""></p>
                                        <p>{{ item.awayName }}</p>
                                        <div class="date_ratio">{{ convertDate(item.showTimeDate) }}</div>
                                    </div>
                                    <div class="wrapper_ratio_all_match" v-for="(item, indexs) in data_name_ratio3.filter(d => d[0] == item.eventId)" :key="indexs">
                                        <div class="ratio_all_home">
                                            <div class="ratio_all_match ratio_all_match0">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item1, index1) in item[1][1]" :key="index1">
                                                        <p>{{item1.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item1, index1) in item[1][1]" :key="index1">
                                                        <p>{{ item1.odds1a }}</p>
                                                        <p>{{ item1.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_all_match border_lf_rg">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item3, index3) in item[1][3]" :key="index3">
                                                        <p>{{item3.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item3, index3) in item[1][3]" :key="index3">
                                                        <p>{{ item3.odds1a }}</p>
                                                        <p>{{ item3.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_half_match border_rg">
                                                <div class="ratio_3" v-for="(item5, index5) in item[1][5]" :key="index5">
                                                    <p>{{ item5.com1 }}</p>
                                                    <p>{{ item5.com2 }}</p>
                                                    <p>{{ item5.comx }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ratio_all_home">
                                            <div class="ratio_all_match ratio_all_match0">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item7, index7) in item[1][7]" :key="index7">
                                                        <p>{{item7.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item7, index7) in item[1][7]" :key="index7">
                                                        <p>{{ item7.odds1a }}</p>
                                                        <p>{{ item7.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_all_match border_lf_rg">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item8, index8) in item[1][8]" :key="index8">
                                                        <p>{{item8.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item8, index8) in item[1][8]" :key="index8">
                                                        <p>{{ item8.odds1a }}</p>
                                                        <p>{{ item8.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_half_match">
                                                <div class="ratio_3" v-for="(item15, index15) in item[1][15]" :key="index15">
                                                    <p>{{ item15.com1 }}</p>
                                                    <p>{{ item15.com2 }}</p>
                                                    <p>{{ item15.comx }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item_ratio_home" v-for="(item, index) in data_name_ratio" :key="index">
                                <div class="wrapper_match_ratio">
                                    <div class="name_match_ratio">
                                        <div class="tournament_home">{{ item.leagueName }}</div>
                                        <p class="textred">{{ item.homeName }}</p>
                                        <p class="vsratio"><img src="../assets/images/vs.png" alt=""></p>
                                        <p>{{ item.awayName }}</p>
                                        <div class="date_ratio">{{ convertDate(item.showTimeDate) }}</div>
                                    </div>
                                    <div class="wrapper_ratio_all_match" v-for="(item, indexs) in data_name_ratio1.filter(d => d[0] == item.eventId)" :key="indexs">
                                        <div class="ratio_all_home">
                                            <div class="ratio_all_match ratio_all_match0">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item1, index1) in item[1][1]" :key="index1">
                                                        <p>{{item1.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item1, index1) in item[1][1]" :key="index1">
                                                        <p>{{ item1.odds1a }}</p>
                                                        <p>{{ item1.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_all_match border_lf_rg">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item3, index3) in item[1][3]" :key="index3">
                                                        <p>{{item3.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item3, index3) in item[1][3]" :key="index3">
                                                        <p>{{ item3.odds1a }}</p>
                                                        <p>{{ item3.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_half_match border_rg">
                                                <div class="ratio_3" v-for="(item5, index5) in item[1][5]" :key="index5">
                                                    <p>{{ item5.com1 }}</p>
                                                    <p>{{ item5.com2 }}</p>
                                                    <p>{{ item5.comx }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ratio_all_home">
                                            <div class="ratio_all_match ratio_all_match0">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item7, index7) in item[1][7]" :key="index7">
                                                        <p>{{item7.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item7, index7) in item[1][7]" :key="index7">
                                                        <p>{{ item7.odds1a }}</p>
                                                        <p>{{ item7.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_all_match border_lf_rg">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item8, index8) in item[1][8]" :key="index8">
                                                        <p>{{item8.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item8, index8) in item[1][8]" :key="index8">
                                                        <p>{{ item8.odds1a }}</p>
                                                        <p>{{ item8.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_half_match">
                                                <div class="ratio_3" v-for="(item15, index15) in item[1][15]" :key="index15">
                                                    <p>{{ item15.com1 }}</p>
                                                    <p>{{ item15.com2 }}</p>
                                                    <p>{{ item15.comx }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper_18_home" v-if="listFilm.length > 0">
                <div class="title_play">
                    <h3><img src="../assets/images/pn.png" alt=""> Phim ngắn</h3>
                </div>
                <div class="slider_film">
                    <Carousel :wrap-around="false" :breakpoints="breakpoints">
                        <Slide v-for="(item, indexs) in listFilm" :key="indexs">
                            <div class="item_film">
                                <a :href="link_video + item.slug" target="_blank">
                                    <div class="img_film">
                                        <img :src="link_imgvideo + item.thumb" alt="">
                                    </div>
                                    <div class="name_film">
                                        <p>{{ item.title }}</p>
                                    </div>
                                </a>
                            </div>
                        </Slide>
                        <template #addons>
                            <pagination />
                        </template>
                    </Carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DetailBxh_vh from '@/components/DetailBxh'
import HLSCore from '@cloudgeek/playcore-hls'
import Swal from 'sweetalert2'

import {reactive} from "vue"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue';

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'

import $ from 'jquery'
// import SocketIO from 'socket.io-client'
$( document ).ready(function() {
    $(document).on('click', '.hide_chat', function() {
        $('.chathome').addClass('hide_chat_click');
        $(this).hide()
        $('.show_chatss').show()
    });
    $(document).on('click', '.show_chatss', function() {
        $('.chathome').removeClass('hide_chat_click');
        $(this).hide()
        $('.hide_chat').show()
    });
    
    $(document).on("click",".tab_bxh ul li",function() {
        $('.tab_bxh ul li').removeClass('active');
        $(this).addClass('active');
    });
    $(document).on("click",".date_ratio_home label",function() {
        $('.date_ratio_home label').parent().removeClass('active');
        $(this).parent().addClass('active');
    });
});
export default {
    name: 'Live_vh',
    setup() {
        const data = reactive({
            description: '',
        })
        return {
            data,
            editor: ClassicEditor,
            breakpoints: {
                0: {
					itemsToShow: 1.5,
					snapAlign: "center"
				},
				575: {
					itemsToShow: 2,
					snapAlign: "center"
				},
				768: {
					itemsToShow: 3,
					snapAlign: "center"
				},
				1024: {
					itemsToShow: 3,
					snapAlign: "center"
				},
                1280: {
					itemsToShow: 4,
					snapAlign: "start"
				},
			}
        }
    },
    data () {
        return {
            tabSelected: '',
            listItemdate1: [],
            tabs: '',
            liveRoom: [],
            country: 'Vietnam',
            players: {},
            volume: 90,
            HLSCore,
            hlight: [],
            listPlayMatch: [],
            check: '',
            checke: '',
            dataRatio: [],
            dataRatio1: [],
            dataRatio2: [],
            dataRatio3: [],
            data_name_ratio: [],
            data_name_ratio1: [],
            data_name_ratio2: [],
            data_name_ratio3: [],
            date1: '',
            date2: '',
            date3: '',
            date4: '',
            month1: '',
            month2: '',
            month3: '',
            month4: '',
            datechange: '',
            listhot: [],
            rt: [],
            rt1: [],
            rt2: [],
            rt3: [],
            rati1: [],
            rati3: [],
            rati5: [],
            rati7: [],
            rati8: [],
            rati15: [],
            listIcon: [],
            name: '',
            content_chat: '',
            stickerDV: '',
            idLv: this.$route.params.id,
            // idLv: parseInt(this.$route.params.id),
            urlLv: this.$route.params.url,
            checkPlay: this.$route.params.check,
            listF8: [],
            listF8s: [],
            editorConfig: {
                toolbar: [ 'italic', 'bold'],
                placeholder: "Nội dung",
            },
            checkMobile: 1,
            linkKeo: '',
            linkCuoc: '',
            linkTele: '',
            linkZalo: '',
            linkLive: '',
            listblv: [],
            listFilm: [],
            listMatchApi: [],
            listMatchApiAll: [],
            detailPlay: [],
            checkClickAdv: 0
        }
    },
    components: {
        ckeditor: CKEditor.component,
        Carousel,
        Slide,
        Pagination,
        DetailBxh_vh
    },
    created () {
        this.getData()
        this.getDataRoomLive()
        this.getlocation()
        this.getDataPlayMatch ()
        this.getDataRatio()
        this.listIconChat()
        setInterval(this.getDataRoomLive, 60000);
        this.getLisIdolAll()
        this.connects()
        this.getInfoLink()
        this.getListblv()
        this.getdataFilm()
        this.getlistApiMatch()
        setTimeout(() => {
            $('.nextvideo').show()
        }, 5000);
    },
    mounted () {
        const isMobile = /Mobi/i.test(navigator.userAgent)
        if(isMobile){
            // mobile
            this.checkMobile = 2
        }else{
            // desktop
            this.checkMobile = 1
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        const today = new Date();
        const tomorrow1 = new Date();
        const tomorrow2 = new Date();
        const tomorrow3 = new Date();
        const tomorrow4 = new Date();
        
        var d1 = new Date(tomorrow1.setDate(today.getDate())).toLocaleDateString("en-US")
        var d2 = new Date(tomorrow2.setDate(today.getDate() + 1)).toLocaleDateString("en-US")
        var d3 = new Date(tomorrow3.setDate(today.getDate() + 2)).toLocaleDateString("en-US")
        var d4 = new Date(tomorrow4.setDate(today.getDate() + 3)).toLocaleDateString("en-US")

        this.date1 = d1.split('/')[1]
        if(this.date1 < 10){
            this.date1 = '0' + this.date1
        }
        this.date2 = d2.split('/')[1]
        if(this.date2 < 10){
            this.date2 = '0' + this.date2
        }
        this.date3 = d3.split('/')[1]
        if(this.date3 < 10){
            this.date3 = '0' + this.date3
        }
        this.date4 = d4.split('/')[1]
        if(this.date4 < 10){
            this.date4 = '0' + this.date4
        }

        this.month1 = d1.split('/')[0]
        this.month2 = d2.split('/')[0]
        this.month3 = d3.split('/')[0]
        this.month4 = d4.split('/')[0]

        setTimeout(() => {
            $.ajax({
                url: 'https://f8betchat.com/api/chat/list-message',
                type: "GET",
                data: { url : this.urlLv },
                dataType: 'json',
                success : function( response ) {
                    var id_append = $('input[name="id-chat"]').val();
                    $.each(response.data, function(){
                        let checkname = this.name.substr(0, 3).toLowerCase();
                        var class_blv = ''
                        if(checkname == 'blv'){
                            class_blv = 'active_color'
                        }else{
                            class_blv = ''
                        }
                        if(this.sticker !== null){
                            this.stickerDV = `<div class="imgsticker"><img src="https://f8betchat.com/${this.sticker}" alt=""></div>`;
                        }else{
                            this.stickerDV = `<div class="body content_chat_edit ${class_blv}">${this.content}</div>`;
                        }
                        this.str = `<div class="flex_chat_icon msg msg-${this.id}">
                                    <div class="img_random_chat">
                                        <img src="https://f8betchat.com/images/cic.gif" alt="">
                                    </div>
                                    <div class="content_text_chats">
                                        <div class="name_time_chat">
                                            <div class="dtxt" title="23 Nov 22, 02:10 AM" data-time="${this.created_at}">${timeAgo(this.created_at)}</div>
                                            <div class="nme ${class_blv}">${this.name}</div>
                                        </div>
                                        ${this.stickerDV}
                                    </div>
                                </div>`;
                        setTimeout(() => {
                            $(`#${id_append}`).append(this.str);
                            var hg = $('.chat-text').prop('scrollHeight');
                            $('.chat-text').animate({ scrollTop: hg+100 }, 5);
                        }, 500);
                    });
                },
            });
        }, 1000);        
            
        function timeAgo(str_date) {
            var d = new Date();
            var UTCsecondsNow = (d.getTime() + d.getTimezoneOffset() * 60 * 1000);

            var date = new Date(str_date);
            var UTCseconds = (date.getTime() + date.getTimezoneOffset() * 60 * 1000);
            var diff = UTCsecondsNow - UTCseconds;
            var tense = 'trước';
            if (diff < 0) {
                tense = 'sau';
                diff = Math.abs(diff);
            }
            if (diff === 0) return 0;
            var years = singular(Math.round(diff / 31557600000), 'năm');
            if (years)
            return years + tense;
            var months = singular(Math.round(diff / 2592000000), 'tháng');
            if (months)
            return months + tense;
            var days = singular(Math.round(diff / 86400000), 'ngày');
            if (days)
            return days + tense;
            var hours = singular(Math.round(diff / 3600000), 'giờ');
            if (hours)
            return hours + tense;
            var mins = singular(Math.round(diff / 60000), 'phút');
            if (mins)
            return mins + tense;
            var secs = singular(Math.round(diff / 1000), 'giây');
            if (secs){
                return secs + tense;
            }
            else return 'Vừa xong';
        }
        function singular(num, str) {
            if (num >= 1) {
                return num + ' ' + str + ' ';
            }
            return '';
        }
    },
    beforeUnmount () {
        
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkKeo = response.data.data.list_data.keo
                this.linkCuoc = response.data.data.list_data.cuoc
                this.linkTele = response.data.data.list_data.telegram
                this.linkZalo = response.data.data.list_data.zalo
                this.linkLive = response.data.data.list_data.live
            })
        },
        changeTab (tab) {
            this.tabSelected = tab
            this.tabs = tab
        },
        getData () {
            this.axios.get(this.api_listmatchdate).then((response) => {
                this.listItemdate1 = response.data.data
                this.tabSelected = Object.keys(this.listItemdate1)[0];
                this.tabs = Object.keys(this.listItemdate1)[0];
            })
        },
        getDataRoomLive () {
            this.axios.get(this.api_listmatchlive).then((response) => {
                this.liveRoom = response.data.data.filter(d => d.id === this.idLv)
                // let datart = this.liveRoom.slice(0,1)
                let dataImage = []
                this.liveRoom.forEach(item => {
                    dataImage.push({...item});

                    let today = new Date();
                    let date = '';
                    if(today.getDate() < 10){
                        date = '0' + today.getDate();
                    }else{
                        date = today.getDate()
                    }
                    let month = today.getMonth()+1;
                    let date_month = date + '_' + month;

                    const form = new FormData();
                    form.append('day', date_month);
                    // 'https://keonhacai.1nguon.vip/b88/'+date_month+'/desktop/data.json' -> get
                    
                    setTimeout(() => {
                        this.axios.post(this.api_ratio, form).then((response) => {
                            
                            this.rt = response.data.oddData.live.event_map; // lấy trận
                            this.rt1 = response.data.oddData.live.odd_map; //lấy tỷ lệ
                        
                            const arr_match = Object.entries(this.rt).map((arr) => ({
                                ...arr[1]
                            }));

                            let arrmatch = []
                            arr_match.forEach(item => {
                                arrmatch.push({...item});
                            });

                            if(dataImage[0].match_auto==1 && (dataImage[0].match_id == 0 || dataImage[0].match_id == null)){
                                let val_1 = arrmatch.filter( product => {
                                    var nameHome = dataImage[0].name_home.split(' ')
                                    var nameAway = dataImage[0].name_away.split(' ')
                                    var checkHome = 0;
                                    var checkAway = 0;
                                    if(product.homeName.toLowerCase().includes(nameHome[0].toLowerCase()) && product.homeName.toLowerCase().includes(nameHome[1].toLowerCase())){
                                        checkHome = 1
                                    }
                                    if(product.awayName.toLowerCase().includes(nameAway[0].toLowerCase()) && product.awayName.toLowerCase().includes(nameAway[1].toLowerCase())){
                                        checkAway = 1
                                    }
                                    if( checkHome == 1 && checkAway == 1 ){
                                        return true
                                    }
                                    // var checkHome = 0;
                                    // var checkAway = 0;
                                    // $.each(nameHome, function() {
                                    //     if(this.toLowerCase() != 'fc' && this.toLowerCase() != 'club' && product.homeName.toLowerCase().includes(this.toLowerCase())){
                                    //         checkHome = 1
                                    //     }
                                    // });
                                    // $.each(nameAway, function() {
                                    //     if(this.toLowerCase() != 'fc' && this.toLowerCase() != 'club' && product.awayName.toLowerCase().includes(this.toLowerCase())){
                                    //         checkAway = 1
                                    //     }
                                    // });
                                    // if( checkHome == 1 && checkAway == 1 ){
                                    //     return true
                                    // }
                                });

                                this.rt3 = val_1.slice(0,1)

                                const array_ratio = Object.entries(this.rt1).map((arr) => ({
                                    ...arr
                                }));

                                if(val_1 != ''){
                                    this.rt2 = array_ratio.filter(d => d[0] === val_1[0].eventId)
                                }else{
                                    this.rt2 = 'null'
                                }
                                if(item.live > 0 && this.rt2 != 'null'){
                                    if(this.rt2[0][1][1]){
                                        this.rati1 = this.rt2[0][1][1]
                                    }
                                    if(this.rt2[0][1][3]){
                                        this.rati3 = this.rt2[0][1][3]
                                    }
                                    if(this.rt2[0][1][5]){
                                        this.rati5 = this.rt2[0][1][5]
                                    }
                                    if(this.rt2[0][1][7]){
                                        this.rati7 = this.rt2[0][1][7]
                                    }
                                    if(this.rt2[0][1][8]){
                                        this.rati8 = this.rt2[0][1][8]
                                    }
                                    if(this.rt2[0][1][15]){
                                        this.rati15 = this.rt2[0][1][15]
                                    }
                                }
                            }else{
                                let val_1 = arrmatch.filter( product => {
                                    return product.eventId == dataImage[0].match_id
                                });

                                this.rt3 = val_1.slice(0,1)

                                const array_ratio = Object.entries(this.rt1).map((arr) => ({
                                    ...arr
                                }));

                                if(val_1 != ''){
                                    this.rt2 = array_ratio.filter(d => d[0] === val_1[0].eventId)
                                }else{
                                    this.rt2 = 'null'
                                }
                                if(item.live > 0 && this.rt2 != 'null'){
                                    if(this.rt2[0][1][1]){
                                        this.rati1 = this.rt2[0][1][1]
                                    }
                                    if(this.rt2[0][1][3]){
                                        this.rati3 = this.rt2[0][1][3]
                                    }
                                    if(this.rt2[0][1][5]){
                                        this.rati5 = this.rt2[0][1][5]
                                    }
                                    if(this.rt2[0][1][7]){
                                        this.rati7 = this.rt2[0][1][7]
                                    }
                                    if(this.rt2[0][1][8]){
                                        this.rati8 = this.rt2[0][1][8]
                                    }
                                    if(this.rt2[0][1][15]){
                                        this.rati15 = this.rt2[0][1][15]
                                    }
                                }
                            }
                        })
                    }, 50);
                    // setInterval(function () {
                        
                    // }, 60000);
                });
            })
        },
        getlocation () {
            this.axios.get('https://geolocation-db.com/json/').then((response) => {
                this.country = response.data.country_name
            })
        },
        viewCore(id, player) {
            this.players[id] = player;
            this.play(id)
        },
        viewCores(id, player) {
            this.players[id] = player;
        },
        play(id) {
            this.players && this.players[id] && this.players[id].play();
        },
        playEnded() {
        },
        pip(id) {
            this.players &&
            this.players[id] &&
            this.players[id].requestPictureInPicture();
        },
        getVideohl () {
            this.axios.get(this.api_highlight).then((response) => {
                var hl = response.data.items
                const getRandom = Math.floor(Math.random() * hl.length);
                this.hlight = hl.find( el => getRandom == el.mid);
            })
        },
        getDataPlayMatch () {
            this.axios.get(this.api_listmatchlive).then((response) => {
                this.listhot = response.data.data.filter(d => d.hot === 1)
                this.listPlayMatch = response.data.data.filter(d => d.live === 1)
            })
        },
        retrieveAllCustomer (url) {
            this.axios.get(url).then(res => {
                if(res.status===200){
                    this.check = 1
                }
            }).catch(e => {
                this.checkes = e
                this.check = 0
            })
            return this.check;
        },
        getDataRatio () {
            let today = new Date();
            let date = today.getDate();
            if(date < 10){
                date = '0' + date
            }
            let month = today.getMonth()+1;
            let date_month = date + '_' + month;

            // 'https://keonhacai.1nguon.vip/b88/'+date_month+'/desktop/data.json' -> get

            const form = new FormData();
            form.append('day', date_month);

            this.axios.post(this.api_ratio, form).then((response) => {
                this.dataRatio = response.data.oddData.today.event_map; // lấy trận
                this.dataRatio1 = response.data.oddData.today.odd_map; //lấy tỷ lệ

                this.dataRatio2 = response.data.oddData.live.event_map; // lấy trận
                this.dataRatio3 = response.data.oddData.live.odd_map;
                
                const arr_match = Object.entries(this.dataRatio).map((arr) => ({
                    ...arr[1]
                }));

                const arr_ratio = Object.entries(this.dataRatio1).map((arrs) => ({
                    ...arrs
                }));
                const arr_match1 = Object.entries(this.dataRatio2).map((arr) => ({
                    ...arr[1]
                }));

                const arr_ratio1 = Object.entries(this.dataRatio3).map((arrs) => ({
                    ...arrs
                }));

                this.data_name_ratio = arr_match
                this.data_name_ratio1 = arr_ratio

                this.data_name_ratio2 = arr_match1
                this.data_name_ratio3 = arr_ratio1
            })
            // setTimeout(() => {
            // }, 50);
        },
        GetDateRatio (event) {
            this.datechange = event.target.value
            if (this.datechange != this.date1 + '_' + this.month1) {
                $('.item_ratio_home_live').hide()
            }else{
                $('.item_ratio_home_live').show()
            }

            const form = new FormData();
            form.append('day', this.datechange);
            // 'https://keonhacai.1nguon.vip/b88/'+this.datechange+'/desktop/data.json' -> get

            this.axios.post(this.api_ratio, form).then((response) => {
                this.dataRatio = response.data.oddData.today.event_map; // lấy trận
                this.dataRatio1 = response.data.oddData.today.odd_map; //lấy tỷ lệ
                
                const arr_match = Object.entries(this.dataRatio).map((arr) => ({
                    ...arr[1]
                }));

                const arr_ratio = Object.entries(this.dataRatio1).map((arrs) => ({
                    ...arrs
                }));
                this.data_name_ratio = arr_match
                this.data_name_ratio1 = arr_ratio
            })
        },
        listIconChat (){
            this.axios.get(this.api_listicon).then((response) => {
                this.listIcon = response.data.data
            })
        },
        sendIconChat (event) {
            var dataImg = event.target.getAttribute('data_img')
            $('.dataImg_chat').val(dataImg)
            $('#chat-submit').trigger('click')
            $('.list_icon_chat').hide()
        },
        sendChat (e) {
            e.preventDefault();
            var thismd = this
            var url_lk = $('input[name="url-chat"]').val();
            var dataimage = $('.dataImg_chat').val()
            var data = {
                url: url_lk,
                name: this.name,
                content: this.content_chat,
                sticker: dataimage
            };
            $.ajax({
                method: "POST",
                url: 'https://f8betchat.com/api/chat/send-message',
                data: data,
                success : function(res) {
                    if(res.success== true){
                        thismd.content_chat = ''
                        $('.dataImg_chat').val('');
                    }
                },
                error: function (res) {
                    Swal.fire({
                        position: 'top-end',
                        showConfirmButton: false,
                        title: res.responseJSON.message,
                        icon: 'error',
                        timer: 2000
                    
                    });
                },
            });
            return false;
        },
        sendChats () {
            var url_lk = $('input[name="url-chat"]').val();
            var dataimage = $('.dataImg_chat').val()
            var data = {
                url: url_lk,
                name: this.name,
                content: this.content_chat,
                sticker: dataimage
            };
            $.ajax({
                method: "POST",
                url: 'https://f8betchat.com/api/chat/send-message',
                data: data,
                success : function(res) {
                    if(res.success== true){
                        $('.dataImg_chat').val('');
                    }
                },
                error: function (res) {
                    Swal.fire({
                        position: 'top-end',
                        showConfirmButton: false,
                        title: res.responseJSON.message,
                        icon: 'error',
                        timer: 2000
                    
                    });
                },
            });
            return false;
        },
        showIconChatLive () {
            $('.list_icon_chat').slideToggle();
        },
        clickToTop () {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        convertDate (date) {
            var datecv = new Date(date);
            return datecv.toLocaleString()
        },
        getLisIdolAll () {
            this.axios.get(this.api_listidol).then((response) => {
                this.listF8 = response.data.data.list_user
                let listif = response.data.data.list_user.filter(d => d.type_live === 1)
                this.listF8s = listif.filter(d => d.live === 1)
            })
        },
        onEditorReady(editor) {
            editor.editing.view.document.on('enter', (evt, data) => {
                if (data.isSoft) {
                    editor.execute('enter');
                } else {
                    this.sendChats()
                    editor.setData( '<p></p>' );
                    evt.stop();
                }
                    data.preventDefault();
                    evt.stop();
                    editor.editing.view.scrollToTheSelection();
            }, { priority: 'high' });
        },
        connects() {
            // this.id_append = $('input[name="id-chat"]').val();
            // var thisAgo = this
            // this.abc = '888888'
            // console.log('abc', this.abc);
            // window.localStorage.setItem('namePath', 'Live_vh')
            // this.socket.on('chat:message_macth',function(result){
            //     var id_append = $('input[name="id-chat"]').val();
            //     console.log('33333', window.localStorage.getItem('namePath'), id_append, thisAgo.urlLv, result);
            //     if( result.message.action === 1 &&  thisAgo.urlLv == result.message.url && window.localStorage.getItem('namePath') == 'Live_vh'){
            //         let checkname = result.message.name.substr(0, 3).toLowerCase();
            //         var class_blv = ''
            //         if(checkname == 'blv'){
            //             class_blv = 'active_color'
            //         }else{
            //             class_blv = ''
            //         }
            //         if(result.message.sticker !== null){
            //             this.stickerDV = `<div class="imgsticker"><img src="https://f8betchat.com/${result.message.sticker}" alt=""></div>`;
            //         }else{
            //             this.stickerDV = `<div class="body content_chat_edit ${class_blv}">${result.message.content}</div>`;
            //         }
            //         // <span class="pic Empty"></span>
            //         this.str = `<div class="flex_chat_icon msg msg-${result.message.id}">
            //                     <div class="img_random_chat">
            //                         <img src="https://f8betchat.com/images/cic.gif" alt="">
            //                     </div>
            //                     <div class="content_text_chats">
            //                         <div class="name_time_chat">
            //                             <div class="dtxt" title="23 Nov 22, 02:10 AM" data-time="${result.message.created_at}">${thisAgo.timeAgo(result.message.created_at)}</div>
            //                             <div class="nme ${class_blv}">${result.message.name}</div>
            //                         </div>
            //                         ${this.stickerDV}
            //                     </div>
            //                 </div>`;
            //         $(`#${id_append}`).append(this.str);
            //         var hg = $('.chat-text').prop('scrollHeight');
            //         $('.chat-text').animate({ scrollTop: hg+100 },500);
            //     }
            //     else{
            //         $(`.msg-${result.message.id}`).remove();
            //     }

            //     this.data_time = $(`#${result.message.url}`).find('.dtxt');
            //     $.each(this.data_time, function(){
            //         this.time = $(this).data('time');
            //         $(this).html( thisAgo.timeAgo(this.time) );
            //     });
            // });
        },
        timeAgo(str_date) {
            var d = new Date();
            var UTCsecondsNow = (d.getTime() + d.getTimezoneOffset() * 60 * 1000);

            var date = new Date(str_date);
            var UTCseconds = (date.getTime() + date.getTimezoneOffset() * 60 * 1000);
            var diff = UTCsecondsNow - UTCseconds;
            var tense = 'trước';
            if (diff < 0) {
                tense = 'sau';
                diff = Math.abs(diff);
            }
            if (diff === 0) return 0;
            var years = this.singular(Math.round(diff / 31557600000), 'năm');
            if (years)
            return years + tense;
            var months = this.singular(Math.round(diff / 2592000000), 'tháng');
            if (months)
            return months + tense;
            var days = this.singular(Math.round(diff / 86400000), 'ngày');
            if (days)
            return days + tense;
            var hours = this.singular(Math.round(diff / 3600000), 'giờ');
            if (hours)
            return hours + tense;
            var mins = this.singular(Math.round(diff / 60000), 'phút');
            if (mins)
            return mins + tense;
            var secs = this.singular(Math.round(diff / 1000), 'giây');
            if (secs){
                return secs + tense;
            }
            else return 'Vừa xong';
        },
        singular(num, str) {
            if (num >= 1) {
                return num + ' ' + str + ' ';
            }
            return '';
        },
        getListblv () {
            this.axios.get(this.api_blv).then((response) => {
                this.listblv = response.data.data
            })
        },
        getdataFilm () {
            this.axios.get(this.api_listvideo).then((response) => {
                this.listFilm = response.data.data
            })
        },
        getlistApiMatch () {
            this.axios.get(this.api_matchCola).then((response) => {
                this.detailPlay = response.data.data.filter(d => d.result.match.id == this.idLv)
                this.listMatchApi = response.data.data.filter(d => d.result.match.matchStatus == 2)
                this.listMatchApiAll = response.data.data

                this.detailPlay.forEach(item => {
                
                    let today = new Date();
                    let date = '';
                    if(today.getDate() < 10){
                        date = '0' + today.getDate();
                    }else{
                        date = today.getDate()
                    }
                    let month = today.getMonth()+1;
                    let date_month = date + '_' + month;

                    const form = new FormData();
                    form.append('day', date_month);

                    setTimeout(() => {
                        this.axios.post(this.api_ratio, form).then((response) => {
                            
                            this.rt = response.data.oddData.live.event_map; // lấy trận
                            this.rt1 = response.data.oddData.live.odd_map; //lấy tỷ lệ
                        
                            const arr_match = Object.entries(this.rt).map((arr) => ({
                                ...arr[1]
                            }));

                            let arrmatch = []
                            arr_match.forEach(item => {
                                arrmatch.push({...item});
                            });

                            let val_1 = arrmatch.filter( product => {
                                var nameHome = item.result.match.homeTeam.slug.split('-')
                                var nameAway = item.result.match.awayTeam.slug.split('-')
                                var checkHome = 0;
                                var checkAway = 0;
                                if(product.homeName.toLowerCase().includes(nameHome[0].toLowerCase()) && product.homeName.toLowerCase().includes(nameHome[1].toLowerCase())){
                                    checkHome = 1
                                }
                                if(product.awayName.toLowerCase().includes(nameAway[0].toLowerCase()) && product.awayName.toLowerCase().includes(nameAway[1].toLowerCase())){
                                    checkAway = 1
                                }
                                if( checkHome == 1 && checkAway == 1 ){
                                    return true
                                }
                            });

                            this.rt3 = val_1.slice(0,1)

                            const array_ratio = Object.entries(this.rt1).map((arr) => ({
                                ...arr
                            }));

                            if(val_1 != ''){
                                this.rt2 = array_ratio.filter(d => d[0] === val_1[0].eventId)
                            }else{
                                this.rt2 = 'null'
                            }
                            if(this.checkPlay == 2 && this.rt2 != 'null'){
                                if(this.rt2[0][1][1]){
                                    this.rati1 = this.rt2[0][1][1]
                                }
                                if(this.rt2[0][1][3]){
                                    this.rati3 = this.rt2[0][1][3]
                                }
                                if(this.rt2[0][1][5]){
                                    this.rati5 = this.rt2[0][1][5]
                                }
                                if(this.rt2[0][1][7]){
                                    this.rati7 = this.rt2[0][1][7]
                                }
                                if(this.rt2[0][1][8]){
                                    this.rati8 = this.rt2[0][1][8]
                                }
                                if(this.rt2[0][1][15]){
                                    this.rati15 = this.rt2[0][1][15]
                                }
                            }
                        })
                    }, 50);
                })
            })
        },
        convertDatetime(d) {
            let gt7 = 7 * 3600
            let timeStamp_change = Number(d) + gt7
            return new Date(timeStamp_change * 1000).toISOString().slice(0, 19).replace('T', ' ')
        },
        replaceName (str) {
            return str.replaceAll('-', ' ')
        },
        convertDateStame (d) {
            let arr = d.split(' ')
            let ngaythang = arr[0]
            let arrNt = ngaythang.split('-')
            let giophut = arr[1]
            let arrGp = giophut.split(':')
            return arrNt[2] + '/' + arrNt[1] + ' ' + arrGp[0] + ':' + arrGp[1]
        },
        nextVideo () {
           this.checkClickAdv = 1 
        }
    }
}
</script>

<style scoped>
</style>
