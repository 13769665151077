<template>
    <div class="container content-margin maxwidth_container ratio_detail">
        <div class="root_ratio_home ratio_tyle">
            <div class="wrapper_ratio_home">
                <div class="title_ratio">
                    <h3>
                        <img src="../assets/images/tl.png" alt="">
                        <span>Tỷ lệ kèo</span>
                    </h3>
                    <ul>
                        <li>Tỷ lệ</li>
                        <li>Tài xỉu</li>
                        <li>1x2</li>
                    </ul>
                </div>
                <form action="" method="post" @change="GetDateRatio($event)">
                    <div class="wp_date_ratio_home">
                        <div class="date_ratio_home active">
                            <input type="radio" checked id="dateratio" class="dateratio" name="dateratio" :value="date1 + '_' + month1">
                            <label for="dateratio">Ngày {{ date1 + '/' + month1 }}</label>
                        </div>
                        <div class="date_ratio_home">
                            <input type="radio" id="dateratio1" class="dateratio" name="dateratio" :value="date2 + '_' + month2">
                            <label for="dateratio1">Ngày {{ date2 + '/' + month2 }}</label>
                        </div>
                        <div class="date_ratio_home">
                            <input type="radio" id="dateratio2" class="dateratio" name="dateratio" :value="date3 + '_' + month3">
                            <label for="dateratio2">Ngày {{ date3 + '/' + month3 }}</label>
                        </div>
                    </div>
                </form>
                <div class="ratio_home_wrapper">
                    <div class="root_rto">
                        <div class="wrapper_title_ratio_home">
                            <div class="title_ratio_home">
                                <div class="title_1">trận đấu</div>
                            </div>
                            <div class="title_ratio_home1 border_edittable">
                                <div class="title_2">
                                    <div class="div_1">Cả trận</div>
                                </div>
                                <div class="wp_three_table">
                                    <div class="one_divRatio">Tỷ lệ</div>
                                    <div class="two_divRatio">Tài xỉu</div>
                                    <div class="three_divRatio">1x2</div>
                                </div>
                            </div>
                            <div class="title_ratio_home1 title_ratio_home2">
                                <div class="title_2">
                                    <div class="div_1">
                                        Hiệp 1
                                    </div>
                                </div>
                                <div class="wp_three_table">
                                    <div class="one_divRatio">Tỷ lệ</div>
                                    <div class="two_divRatio">Tài xỉu</div>
                                    <div class="three_divRatio">1x2</div>
                                </div>
                            </div>
                        </div>
                        <div class="wrapper_item_ratio_home">
                            <div class="item_ratio_home item_ratio_home_live" v-for="(item, index) in data_name_ratio2" :key="index">
                                <div class="wrapper_match_ratio">
                                    <div class="name_match_ratio">
                                        <div class="tournament_home">{{ item.leagueName }}</div>
                                        <p class="textred">{{ item.homeName }}</p>
                                        <p class="vsratio"><img src="../assets/images/vs.png" alt=""></p>
                                        <p>{{ item.awayName }}</p>
                                        <div class="date_ratio">{{ convertDate(item.showTimeDate) }}</div>
                                    </div>
                                    <div class="wrapper_ratio_all_match" v-for="(item, indexs) in data_name_ratio3.filter(d => d[0] == item.eventId)" :key="indexs">
                                        <div class="ratio_all_home">
                                            <div class="ratio_all_match ratio_all_match0">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item1, index1) in item[1][1]" :key="index1">
                                                        <p>{{item1.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item1, index1) in item[1][1]" :key="index1">
                                                        <p>{{ item1.odds1a }}</p>
                                                        <p>{{ item1.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_all_match border_lf_rg">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item3, index3) in item[1][3]" :key="index3">
                                                        <p>{{item3.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item3, index3) in item[1][3]" :key="index3">
                                                        <p>{{ item3.odds1a }}</p>
                                                        <p>{{ item3.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_half_match border_rg">
                                                <div class="ratio_3" v-for="(item5, index5) in item[1][5]" :key="index5">
                                                    <p>{{ item5.com1 }}</p>
                                                    <p>{{ item5.com2 }}</p>
                                                    <p>{{ item5.comx }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ratio_all_home">
                                            <div class="ratio_all_match ratio_all_match0">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item7, index7) in item[1][7]" :key="index7">
                                                        <p>{{item7.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item7, index7) in item[1][7]" :key="index7">
                                                        <p>{{ item7.odds1a }}</p>
                                                        <p>{{ item7.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_all_match border_lf_rg">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item8, index8) in item[1][8]" :key="index8">
                                                        <p>{{item8.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item8, index8) in item[1][8]" :key="index8">
                                                        <p>{{ item8.odds1a }}</p>
                                                        <p>{{ item8.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_half_match">
                                                <div class="ratio_3" v-for="(item15, index15) in item[1][15]" :key="index15">
                                                    <p>{{ item15.com1 }}</p>
                                                    <p>{{ item15.com2 }}</p>
                                                    <p>{{ item15.comx }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item_ratio_home" v-for="(item, index) in data_name_ratio" :key="index">
                                <div class="wrapper_match_ratio">
                                    <div class="name_match_ratio">
                                        <div class="tournament_home">{{ item.leagueName }}</div>
                                        <p class="textred">{{ item.homeName }}</p>
                                        <p class="vsratio"><img src="../assets/images/vs.png" alt=""></p>
                                        <p>{{ item.awayName }}</p>
                                        <div class="date_ratio">{{ convertDate(item.showTimeDate) }}</div>
                                    </div>
                                    <div class="wrapper_ratio_all_match" v-for="(item, indexs) in data_name_ratio1.filter(d => d[0] == item.eventId)" :key="indexs">
                                        <div class="ratio_all_home">
                                            <div class="ratio_all_match ratio_all_match0">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item1, index1) in item[1][1]" :key="index1">
                                                        <p>{{item1.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item1, index1) in item[1][1]" :key="index1">
                                                        <p>{{ item1.odds1a }}</p>
                                                        <p>{{ item1.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_all_match border_lf_rg">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item3, index3) in item[1][3]" :key="index3">
                                                        <p>{{item3.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item3, index3) in item[1][3]" :key="index3">
                                                        <p>{{ item3.odds1a }}</p>
                                                        <p>{{ item3.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_half_match border_rg">
                                                <div class="ratio_3" v-for="(item5, index5) in item[1][5]" :key="index5">
                                                    <p>{{ item5.com1 }}</p>
                                                    <p>{{ item5.com2 }}</p>
                                                    <p>{{ item5.comx }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ratio_all_home">
                                            <div class="ratio_all_match ratio_all_match0">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item7, index7) in item[1][7]" :key="index7">
                                                        <p>{{item7.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item7, index7) in item[1][7]" :key="index7">
                                                        <p>{{ item7.odds1a }}</p>
                                                        <p>{{ item7.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_all_match border_lf_rg">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item8, index8) in item[1][8]" :key="index8">
                                                        <p>{{item8.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item8, index8) in item[1][8]" :key="index8">
                                                        <p>{{ item8.odds1a }}</p>
                                                        <p>{{ item8.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_half_match">
                                                <div class="ratio_3" v-for="(item15, index15) in item[1][15]" :key="index15">
                                                    <p>{{ item15.com1 }}</p>
                                                    <p>{{ item15.com2 }}</p>
                                                    <p>{{ item15.comx }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Ratio_vh',
    data () {
        return {
            tabSelected: '',
            tabs: '',
            dataRatio: [],
            dataRatio1: [],
            dataRatio2: [],
            dataRatio3: [],
            data_name_ratio: [],
            data_name_ratio1: [],
            data_name_ratio2: [],
            data_name_ratio3: [],
            date1: '',
            date2: '',
            date3: '',
            date4: '',
            month1: '',
            month2: '',
            month3: '',
            month4: '',
            datechange: '',
            rt: [],
            rt1: [],
            rt2: [],
            rt3: [],
            rati1: [],
            rati3: [],
            rati5: [],
            rati7: [],
            rati8: [],
            rati15: [],
        }
    },
    components: {
        
    },
    created () {
        this.getDataRatio()
        window.localStorage.setItem('nameCountry', '39')
    },
    mounted () {
        const today = new Date();
        const tomorrow1 = new Date();
        const tomorrow2 = new Date();
        const tomorrow3 = new Date();
        const tomorrow4 = new Date();
        
        var d1 = new Date(tomorrow1.setDate(today.getDate())).toLocaleDateString("en-US")
        var d2 = new Date(tomorrow2.setDate(today.getDate() + 1)).toLocaleDateString("en-US")
        var d3 = new Date(tomorrow3.setDate(today.getDate() + 2)).toLocaleDateString("en-US")
        var d4 = new Date(tomorrow4.setDate(today.getDate() + 3)).toLocaleDateString("en-US")

        this.date1 = d1.split('/')[1]
        if(this.date1 < 10){
            this.date1 = '0' + this.date1
        }
        this.date2 = d2.split('/')[1]
        if(this.date2 < 10){
            this.date2 = '0' + this.date2
        }
        this.date3 = d3.split('/')[1]
        if(this.date3 < 10){
            this.date3 = '0' + this.date3
        }
        this.date4 = d4.split('/')[1]
        if(this.date4 < 10){
            this.date4 = '0' + this.date4
        }

        this.month1 = d1.split('/')[0]
        this.month2 = d2.split('/')[0]
        this.month3 = d3.split('/')[0]
        this.month4 = d4.split('/')[0]
    },
    beforeUnmount () {
        
    },
    methods: {
        changeTab (tab) {
            this.tabSelected = tab
            this.tabs = tab
        },
        getDataRatio () {
            let today = new Date();
            let date = today.getDate();
            if(date < 10){
                date = '0' + date
            }
            let month = today.getMonth()+1;
            let date_month = date + '_' + month;

            const form = new FormData();
            form.append('day', date_month);

            this.axios.post(this.api_ratio, form).then((response) => {
                this.dataRatio = response.data.oddData.today.event_map; // lấy trận
                this.dataRatio1 = response.data.oddData.today.odd_map; //lấy tỷ lệ 

                this.dataRatio2 = response.data.oddData.live.event_map; // lấy trận
                this.dataRatio3 = response.data.oddData.live.odd_map;
                
                const arr_match = Object.entries(this.dataRatio).map((arr) => ({
                    ...arr[1]
                }));

                const arr_ratio = Object.entries(this.dataRatio1).map((arrs) => ({
                    ...arrs
                }));
                const arr_match1 = Object.entries(this.dataRatio2).map((arr) => ({
                    ...arr[1]
                }));

                const arr_ratio1 = Object.entries(this.dataRatio3).map((arrs) => ({
                    ...arrs
                }));

                this.data_name_ratio = arr_match
                this.data_name_ratio1 = arr_ratio

                this.data_name_ratio2 = arr_match1
                this.data_name_ratio3 = arr_ratio1
            })
        },
        GetDateRatio (event) {
            this.datechange = event.target.value
            if (this.datechange != this.date1 + '_' + this.month1) {
                $('.item_ratio_home_live').hide()
            }else{
                $('.item_ratio_home_live').show()
            }

            const form = new FormData();
            form.append('day', this.datechange);

            this.axios.post(this.api_ratio, form).then((response) => {
                this.dataRatio = response.data.oddData.today.event_map; // lấy trận
                this.dataRatio1 = response.data.oddData.today.odd_map; //lấy tỷ lệ
                
                const arr_match = Object.entries(this.dataRatio).map((arr) => ({
                    ...arr[1]
                }));

                const arr_ratio = Object.entries(this.dataRatio1).map((arrs) => ({
                    ...arrs
                }));
                this.data_name_ratio = arr_match
                this.data_name_ratio1 = arr_ratio
            })
        },
        convertDate (date) {
            var datecv = new Date(date);
            return datecv.toLocaleString()
        },
    }
}
</script>

<style scoped>
</style>
