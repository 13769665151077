<template>
    <div :class="classwp_header">
        <div class="banner_top_home">
            <a :href="linkCuoc" target="_blank">
                <img src="../assets/images/top.gif" alt="">
            </a>
        </div>
        <div class="banner_top_home_mb">
            <a :href="linkCuoc" target="_blank">
                <img src="../assets/images/bn_head_mb.gif" alt="">
            </a>
        </div>
        <header id="header" :class="classHeader" class="header has-sticky sticky-shrink">
            <div class="container maxwidth_container">
                <div class="menu_desktop">
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-12 left-logo">
                            <div id="logo" class="flex-col logo">
                                <router-link v-if="$route.path != '/'" class="nav-item" to='/' title="VAOHANG trang chuyên live bóng đá, xem bóng đá trực tiếp full HD - F8BET" rel="home">
                                    <img src="../assets/images/logo.png" class="header_logo header-logo" alt="VAOHANG trang chuyên live bóng đá, xem bóng đá trực tiếp full HD">
                                </router-link>
                                <a v-else class="nav-item" href='/' title="VAOHANG trang chuyên live bóng đá, xem bóng đá trực tiếp full HD - F8BET" rel="home">
                                    <img src="../assets/images/logo.png" class="header_logo header-logo" alt="VAOHANG trang chuyên live bóng đá, xem bóng đá trực tiếp full HD">
                                </a>
                            </div>
                        </div>
                        <div class="col-md-9 col-sm-9 col-xs-12 right-menu">
                            <div class="wp-menu">
                                <ul class="header-nav">
                                    <li>
                                        <router-link class="nav-item" to='/' v-if="$route.path != '/'">Trang chủ</router-link>
                                        <a class="router-link-active nav-item" href='/' v-else>Trang chủ</a>
                                    </li>

                                    <!-- <li><router-link class="nav-item" to='/ratio'>Tỷ lệ kèo</router-link></li>
                                    <li><router-link class="nav-item" to='/lich-thi-dau'>Lịch thi đấu</router-link></li>
                                    <li><router-link class="nav-item" to='/kqbd'>Kết quả</router-link></li>
                                    <li><router-link class="nav-item" to='/bxh'>BXH</router-link></li> -->

                                    <li><a class="nav-item" href="javascript:;">Tỷ lệ kèo</a></li>
                                    <li><a class="nav-item" href="javascript:;">Lịch thi đấu</a></li>
                                    <li><a class="nav-item" href="javascript:;">Kết quả</a></li>
                                    <li><a class="nav-item" href="javascript:;">BXH</a></li>

                                    <li class="login_header"><a :href="linkCuoc" target="_blank">Đăng nhập</a></li>
                                    <li class="register_header"><a @click="showModal = true">Đăng ký</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="'menu_mobile ' + active">
                    <p class="nav-menu-mobile" @click="clickMenu"><img src="../assets/images/menu.png" alt=""></p>
                    <p class="nav-menu-mobile close-mn" @click="clickRemove"><img src="../assets/images/close.png" alt=""></p>
                    <p @click="clickRemove" class="mgbt_mobile">
                        <router-link v-if="$route.path != '/'" class="nav-item" to='/' title="VAOHANG trang chuyên live bóng đá, xem bóng đá trực tiếp full HD - F8BET" rel="home">
                            <img src="../assets/images/logo.png" class="logo-mobile" alt="VAOHANG trang chuyên live bóng đá, xem bóng đá trực tiếp full HD">
                        </router-link>
                        <a v-else class="nav-item" href='/' title="VAOHANG trang chuyên live bóng đá, xem bóng đá trực tiếp full HD - F8BET" rel="home">
                            <img src="../assets/images/logo.png" class="logo-mobile" alt="VAOHANG trang chuyên live bóng đá, xem bóng đá trực tiếp full HD">
                        </a>
                    </p>
                    <div class="menu-mb">
                        <ul>
                            <li @click="clickRemove">
                                <router-link class="nav-item" to='/' v-if="$route.path != '/'">Trang chủ</router-link>
                                <a class="nav-item router-link-active" href='/' v-else>Trang chủ</a>
                            </li>
                            <li @click="clickRemove"><router-link class="nav-item" to='/ratio'>Tỷ lệ kèo</router-link></li>
                            <li @click="clickRemove"><router-link class="nav-item" to='/lich-thi-dau'>Lịch thi đấu</router-link></li>
                            <li @click="clickRemove"><router-link class="nav-item" to='/kqbd'>Kết quả</router-link></li>
                            <li @click="clickRemove"><router-link class="nav-item" to='/bxh'>BXH</router-link></li>
                            <li @click="clickRemove"><a :href="linkCuoc" target="_blank">Đăng nhập</a></li>
                            <li><a @click="clickRemoves">Đăng ký</a></li>
                            <!-- <li @click="clickRemove"><router-link class="nav-item" to='/tin-tuc'>Tin tức 24h</router-link></li>
                            <li class="mg-right"><a :href="linkCuoc" target="_blank">Đăng ký</a></li>
                            <li><a :href="linkCuoc" target="_blank">Đăng nhập</a></li> -->
                        </ul>
                    </div>
                </div>
                <!-- modal register -->
                <div id="modal-example" class="modal-login">
                    <div class="overlay" v-if="showModal" @click="showModal = false"></div>
                    <div class="modal" v-if="showModal">
                        <div class="wp-modal-register">
                            <div class="header-modal">
                                <button class="close-modal_login" @click="showModal = false"><img src="../assets/images/close_md.png" alt=""></button>
                            </div>
                            <div class="content-modal_tab">
                                <div>
                                    <div class="logo_modal_login">
                                        <div class="logo_mdal">
                                            <div class="img_md">
                                                <img src="../assets/images/logo.png" alt="">
                                            </div>
                                            <div class="img_md1">
                                                <img src="../assets/images/logomd1.png" alt="">
                                            </div>
                                        </div>
                                        <div class="img_pathner_md">
                                            <img src="../assets/images/logomd2.png" alt="">
                                        </div>
                                    </div>
                                    <div class="form_dangky">
                                        <form action="" method="post" id="frm_register" @submit="register">
                                            <div class="row">
                                                <div class="col-md-6 col-sm-6 col-xs-12 left_frm_register">
                                                    <div class="wrapper_left_register">
                                                        <div class="input_dk">
                                                            <label><img src="../assets/images/us1.png" alt=""> Tên đăng nhập</label>
                                                            <input type="text" v-model="username" placeholder="">
                                                            <p class="lable_title"><span>*</span> Lớn hơn 5 ký tự, bằng chữ, không thêm ký tự đặc biệt</p>
                                                        </div>
                                                        <div class="input_dk">
                                                            <label><img src="../assets/images/pw1.png" alt=""> Mật khẩu thành viên</label>
                                                            <input type="password" v-model="password" placeholder="">
                                                            <p class="lable_title"><span>*</span> Hơn 6 ký tự, bao gồm chữ cái và số</p>
                                                        </div>
                                                        <div class="input_dk">
                                                            <label><img src="../assets/images/pw1.png" alt=""> Xác nhận mật khẩu</label>
                                                            <input type="password" v-model="password_confirm" placeholder="">
                                                            <p class="lable_title"><span>*</span> Vui lòng xác nhận lại mật khẩu của bạn</p>
                                                        </div>
                                                        <div class="input_dk input0">
                                                            <p class="checkagea"><img src="../assets/images/check.png" alt=""> Tôi đã đủ tuổi và đồng ý <span>"Chính sách thành viên"</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-12 right_frm_register">
                                                    <div class="wrapper_left_register">
                                                        <div class="input_dk">
                                                            <label><img src="../assets/images/ht.png" alt=""> Họ tên</label>
                                                            <input type="text" v-model="name" placeholder="">
                                                            <p class="lable_title"><span>*</span> Viết họ tên chữ in hoa không dấu</p>
                                                        </div>
                                                        <div class="input_dk">
                                                            <label><img src="../assets/images/em.png" alt=""> Địa chỉ email</label>
                                                            <input type="email" v-model="email" placeholder="">
                                                            <p class="lable_title"><span>*</span> Nhập chính xác để khôi phục lại mật khẩu khi quên</p>
                                                        </div>
                                                        <div class="input_dk">
                                                            <label><img src="../assets/images/dt.png" alt=""> Số điện thoại</label>
                                                            <input type="text" v-model="phone" placeholder="">
                                                            <p class="lable_title"><span>*</span> Số điện thoại 10 số, Ví dụ: +84 837 999 988</p>
                                                        </div>
                                                        <div class="input_dk">
                                                            <label><img src="../assets/images/ma.png" alt=""> Mã xác minh</label>
                                                            <div class="codecapchar_dk">
                                                                <input type="text" class="codeCapchar" placeholder="">
                                                                <div class="capcharCheck">
                                                                    <span>{{ codeCapchar }}</span>
                                                                    <button type="button" @click="reloadCapchar" class="resetcapchar"></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="input_dk input1">
                                                            <p class="checkagea"><img src="../assets/images/check.png" alt=""> Tôi đã đủ tuổi và đồng ý <span>"Chính sách thành viên"</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="btn_dangky">
                                                <button type="submit">Đăng ký ngay</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal register -->
            </div>
            <CurvedBottomNavigation :options="options" v-model="selected" background-color='#111219' foreground-color='#1F9AFF' />
        </header>
        <div class="banner_left">
            <a :href="linkCuoc" target="_blank">
                <img src="../assets/images/left.gif" alt="">
            </a>
        </div>
        <div class="banner_right">
            <a :href="linkCuoc" target="_blank">
                <img src="../assets/images/right.gif" alt="">
            </a>
        </div>
        <div class="banner_bottom">
            <div class="wp_bottom_bn">
                <a :href="linkCuoc" target="_blank">
                    <img src="../assets/images/bottom.gif" alt="">
                </a>
                <img src="../assets/images/cls.png" alt="" class="imgClose" @click="hideBanner">
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Header_Vaohang',
    data () {
        return {
            active: '',
            selected: 1,
            options: [
                { id: 2, icon: "falive_icon", title: "Kết quả", path: { name: "Kqbd_vh" } },
                { id: 3, icon: "fahome_ratio", title: "Tỷ lệ kèo", path: { name: "Ratio_vh" } },
                { id: 1, icon: "fahome_icon", title: "Trang chủ", path: { name: "Home_vaohang" } },
                { id: 4, icon: "falive18_icon", title: "Lịch thi đấu", path: { name: "Lichthidau_vh" } },
                { id: 5, icon: "fasupport", title: "BXH", path: { name: "Bxh_vh" } },
            ],
            linkKeo: '',
            linkCuoc: '',
            classHeader: '',
            classwp_header: '',
            showModal: false,
            name: '',
            email: '',
            phone: '',
            username: '',
            password: '',
            password_confirm: '',
            codeCapchar: Math.floor(1000 + Math.random() * 9000)
        }
    },
    created () {
        this.getInfoLink()
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        clickMenu () {
            this.active = 'active'
        },
        clickRemove () {
            this.active = ''
        },
        clickRemoves () {
            this.active = ''
            this.showModal = true
        },
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkKeo = response.data.data.list_data.keo
                this.linkCuoc = response.data.data.list_data.cuoc
            })
        },
        handleScroll () {
            if (window.scrollY > 150) {
                this.classHeader = 'active'
                this.classwp_header = 'root_headerEdit'
            }else{
                this.classHeader = ''
                this.classwp_header = ''
            }
        },
        register (e) {
            e.preventDefault()
            let capchar = $('.codeCapchar').val()
            if(capchar == ''){
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Vui lòng nhập mã xác nhận',
                    showConfirmButton: false,
                    timer: 3000
                })
            }else if(capchar != this.codeCapchar){
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Mã xác nhận không đúng',
                    showConfirmButton: false,
                    timer: 3000
                })
            }else{
                $('.loading_show').show().fadeIn(10)
                this.axios.post(this.api_register, {
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                    username: this.username,
                    password: this.password,
                    password_confirm: this.password_confirm,
                    referrer: 'LIVEC102',
                    type_register: 2
                }).then(res => {
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    this.showModal = false
                    this.name = this.username = this.email = this.password = this.password_confirm = this.phone = ''
                    this.codeCapchar = Math.floor(1000 + Math.random() * 9000)
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    if (e.response.status === 404) {
                        this.$swal({
                            position: 'top-end',
                            icon: 'error',
                            title: e.response.data.message,
                            showConfirmButton: false,
                            timer: 3000
                        })
                    }
                })
            }
        },
        reloadCapchar () {
            this.codeCapchar = Math.floor(1000 + Math.random() * 9000)
        },
        hideBanner () {
            $('.banner_bottom').hide()
        }
    }
}
</script>
<style></style>
