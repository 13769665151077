<template>
    <div class="container content-margin">
        <!-- <div class="icon_adv_mobile icon_adv_mobile1">
            <ul>
                <li>
                    <a :href="link_keobong" target="_blank"><img src="../assets/images/btn1.gif" alt=""></a>
                </li>
                <li>
                    <a :href="link_banner" target="_blank"><img src="../assets/images/btn.gif" alt=""></a>
                </li>
            </ul>
        </div> -->
        <!-- <div class="kqbd-vaohang"> -->
            <!-- https://www.aiscore.com/vi?width=1200&amp;theme=blue -->
            <!-- <iframe src="https://www.scorebat.com/embed/livescore/" height="100%" width="1200" scrolling="auto" border="0" frameborder="0"></iframe> -->
        <!-- </div> -->
        <!-- <div class="root-list-play">
            <div class="wp-list-play">
                <div class="cat-play">
                    <ul class="tabs">
                        <li class="tab" v-bind:class="{active: tabSelected == 'allmatch'}" v-on:click="changeTab('allmatch')">Tất cả trận đấu</li>
                        <li class="tab" v-bind:class="{active: tabSelected == 'listmatch'}" v-on:click="changeTab('listmatch')">Danh sách BLV</li>
                        <li class="tab" v-bind:class="{active: tabSelected == 'hotmatch'}" v-on:click="changeTab('hotmatch')">Trận Hot</li>
                        <li class="tab" v-bind:class="{active: tabSelected == 'playmatch'}" v-on:click="changeTab('playmatch')">Đang phát</li>
                    </ul>
                </div>
                <div class="list-match">
                    <div class="tab-content">
                        <div class="allmatch" v-if="tabSelected === 'allmatch'">
                            <Listall_vh />
                        </div>
                        <div class="listmatch" v-if="tabSelected === 'listmatch'">
                            <Listblv_vaohang />
                        </div>
                        <div class="hotmatch" v-if="tabSelected === 'hotmatch'">
                            <Listplay_vh />
                        </div>
                        <div class="playmatch"  v-if="tabSelected === 'playmatch'">
                            <Listhot_vh />
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <Banner_adv /> -->
        <!-- <div class="col-inner new-top">
            <section class="home-section section-news section-news-2">
                <div class="container new-home-ed">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12 padding_editor">
                            <div class="d-flex justify-content-between align-items-center text-uppercase section-title">
                                <span class="d-flex align-items-center title-section">
                                    <div class="sec_title tit-new" style="margin-left: -5px;">
                                        <span class="txt">TIN TỨC BÓNG ĐÁ HÔM NAY <img src="../assets/images/arrow_right.svg" alt=""></span>
                                    </div>
                                </span>
                            </div>
                            <div class="row row-bxh-home">
                                <div class="col-md-8 col-sm-8 col-xs-12 left-new-home">
                                    <New_vaohang />
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 right-bxh-home">
                                    <Bxh_right />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div> -->
        <div class="row row-main main_page_edit">
            <div class="large-12 col ltd_container">
                <div class="col-inner root_result">
                    <div class="wp-schedule wrapper_match_calenda wp_result_soccer">
                        <div class="item_tournament item_tournament_result" v-for="(item, index) in Listitems" :key="index">
                            <div class="wp_item_tournament" v-for="(items, indexs) in item" :key="indexs">
                                <h3 :class="'title_none title' + indexs"><span>{{ items.competitionName }}</span></h3>
                                <div class="match_tournament">
                                    <div class="div_02">{{ items.kickoffTimeGmt7 }}</div>
                                    <div class="div_04">
                                        <span class="name_home_ltd">{{ items.homeName }}</span>
                                        <span class="vs_ltd">{{ items.homeScore }} - {{ items.awayScore }}</span>
                                        <span class="name_away_ltd">{{ items.awayName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
// import New_vaohang from '@/components/New'
// import Banner_adv from '@/components/Banner'
// import Listblv_vaohang from '@/components/Listblv'
// import Listall_vh from '@/components/Listall'
// import Listhot_vh from '@/components/Listhot'
// import Listplay_vh from '@/components/Listplay'
// import Bxh_right from '@/components/Bxhright'
export default {
    name: 'Kqbd_vh',
    data () {
        return {
            Listitems: [],
            dataRs: [],
            dataRs1: [],
        }
    },
    components: {
    },
    created () {
        this.getTournament()
    },
    methods: {
        getTournament () {
            // api_listResult
            this.axios.get(this.api_kqbd).then((response) => {
                this.dataRs = response.data.data.competitionMap;
                this.dataRs1 = response.data.data.eventMap;

                const arr_Result = Object.entries(this.dataRs).map((arr) => ({
                    ...arr[1]
                }));
                const sortData = arr_Result.sort((a, b) => {
                    return (b.isPin || 0) - (a.isPin || 0);
                });
                const arr_Result1 = Object.entries(this.dataRs1).map((arr1) => ({
                    ...arr1[1]
                }));

                var listRs = [];
                sortData.forEach((item)=>{
                    let idCompetition = item.competitionId
                    var val_1 = arr_Result1.filter( product => {
                        return product.competitionId == idCompetition
                    })
                    listRs.push(val_1)
                });
                this.Listitems = listRs
            })
        },
        format_date(value){
            if (value) {
                return moment(String(value)).format('HH:mm DD/MM')
            }
        },
    }
}
</script>
<style>
</style>
